import { TranslateService as AbstractTranslateService } from "@mb-home/lib";

class TranslateService extends AbstractTranslateService {
  // ========== ABSTRACT FUNCTIONS: ========== //

  async getStorageItem(key: string) {
    return localStorage.getItem(key);
  }
  async setStorageItem(key: string, value: string) {
    return localStorage.setItem(key, value);
  }

  getSystemLang() {
    if (navigator.language) {
      return navigator.language.substring(0, 2);
    } else {
      return null;
    }
  }

  // ========== ABSTRACT FUNCTIONS. ========== //
}

const instance = new TranslateService();

export function T(
  key: string,
  parameters?: {
    [key: string]: string | number;
  }
) {
  return instance.T(key, parameters);
}
export function getCurrentLang() {
  return instance.getCurrentLang();
}
export function getLang() {
  return instance.getLang();
}
export function setLang(lang: string) {
  return instance.setLang(lang);
}
export function dateLocal() {
  return instance.dateLocal();
}
export function timeDiffFromNow(date: number | Date) {
  return instance.timeDiffFromNow(date);
}
export function formattedFullDate(date: number | Date) {
  return instance.formattedFullDate(date);
}
export function formattedFullDateNumeric(date: number | Date) {
  return instance.formattedFullDateNumeric(date);
}
export const LANGS = instance.LANGS;
