import { useMemo } from "react";
import { Button, Paper, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

import MailOutlineIcon from "@mui/icons-material/MailOutline";

import { T, setLang } from "../services/TranslateService";

import logo from "../img/logo.png";
import { backgroundImage } from "@mb-home/lib";
import AppStoreLinks from "../components/AppStoreLinks";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function AccountRemove() {
  const query = useQuery();
  setLang(query.get("lang") || localStorage.lng);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        height: "100vh",
        minHeight: 460,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <img src={logo} width="100" alt="logo" />
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: 3,
        }}
      >
        <Typography
          sx={{
            color: "#FFFFFF",
            textAlign: "center",
            marginBottom: 3,
          }}
        >
          {T("web.account-remove")}
        </Typography>
        <Button
          startIcon={<MailOutlineIcon />}
          sx={{ alignSelf: "center", paddingX: 2, textTransform: "none" }}
          onClick={() => window.open("mailto:support@mohanet.com")}
        >
          support@mohanet.com
        </Button>
      </Paper>
      <AppStoreLinks />
    </div>
  );
}
