import { useState, useEffect, useMemo, useCallback } from "react";
import {
  TextField,
  Container,
  Button,
  Typography,
  Box,
  styled,
} from "@mui/material";
import { useLocation } from "react-router-dom";

import { T, setLang } from "../services/TranslateService";

import logo from "../img/logo.png";
import { backgroundImage } from "@mb-home/lib";

const SERVER = process.env.__DEV__
  ? "https://home-api-dev.monitoringbook.com/api"
  : "https://home-api.monitoringbook.com/api";

const StyledButton = styled(Button)(({ theme }) => ({
  "&.MuiButton-contained": {
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    marginTop: 12,
    marginBottom: 12,
  },
}));

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

// TODO: Ellenőrizni, hogy tényleg.
interface LoginResponse {
  valid: boolean;
  login: string;
}

interface ResetResponse {
  success: boolean;
}

interface NewRequestResponse {
  email: string;
}

export default function ResetPassword() {
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [passError, setPassError] = useState(false);
  const [loginResponse, setLoginResponse] = useState<LoginResponse | null>(
    null
  );
  const [resetResponse, setResetResponse] = useState<ResetResponse | null>(
    null
  );
  const [emailOrlogin, setEmailOrlogin] = useState("");
  const [newRequestResponse, setNewRequestResponse] =
    useState<NewRequestResponse | null>(null);

  const query = useQuery();
  setLang(query.get("lang") as string);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (pass1 !== pass2 || !pass1) {
        setPassError(true);
        return;
      }
      setPassError(false);

      fetch(`${SERVER}/v1.1/reset_password.php`, {
        method: "POST",
        body: JSON.stringify({
          key: query.get("key"),
          pass: pass1,
        }),
      })
        .then((res) => res.json())
        .then(setResetResponse)
        .catch((err) => console.log("fetch failed", err));
    },
    [query, pass1, pass2]
  );

  const handleNewRequestSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      fetch(`${SERVER}/send_lost_pass.php`, {
        method: "POST",
        body: JSON.stringify({
          account: emailOrlogin,
          lang: query?.get("lang"),
        }),
      })
        .then((res) => res.json())
        .then((res) => res?.length > 0 && setNewRequestResponse(res[0]))
        .catch((err) => console.log("fetch failed", err));
    },
    [query, emailOrlogin]
  );

  useEffect(() => {
    if (loginResponse) {
      return;
    }
    fetch(`${SERVER}/v1.1/reset_password.php`, {
      method: "POST",
      body: JSON.stringify({
        key: query.get("key"),
      }),
    })
      .then((res) => res.json())
      .then(setLoginResponse)
      .catch((err) => console.log("fetch failed", err));
  }, [query, loginResponse]);

  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container
        maxWidth="xs"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "40vh",
            maxHeight: 350,
            minHeight: 150,
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ height: "100%", maxHeight: 250 }}
          />
        </Box>
        {loginResponse?.valid && (
          <Typography color="primary">
            {T("resetpass.info", { login: loginResponse.login })}
          </Typography>
        )}
        {loginResponse?.valid && !resetResponse && (
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              type="password"
              variant="standard"
              value={pass1}
              onChange={(e) => {
                setPass1(e.target.value);
                setPassError(false);
              }}
              error={passError}
              label={T("settings.newpass")}
              fullWidth
              required
              margin="normal"
              autoComplete="new-password"
            />
            <TextField
              type="password"
              variant="standard"
              value={pass2}
              onChange={(e) => {
                setPass2(e.target.value);
                setPassError(false);
              }}
              error={passError}
              label={T("resetpass.confirm")}
              fullWidth
              required
              margin="normal"
              autoComplete="new-password"
            />
            <StyledButton
              type="submit"
              variant="contained"
              fullWidth
              disableElevation
              disabled={!pass1 || !pass2}
            >
              {T("settings.savepass")}
            </StyledButton>
          </form>
        )}
        {loginResponse &&
          !loginResponse.valid &&
          !newRequestResponse?.email && (
            <>
              <Typography color="primary">{T("resetpass.expired")}</Typography>
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleNewRequestSubmit}
              >
                <TextField
                  variant="standard"
                  value={emailOrlogin}
                  onChange={(e) => setEmailOrlogin(e.target.value)}
                  error={passError}
                  label={T("login.forgot.input")}
                  fullWidth
                  required
                  margin="normal"
                />
                <StyledButton
                  type="submit"
                  variant="contained"
                  fullWidth
                  disableElevation
                  disabled={!emailOrlogin}
                >
                  {T("login.forgot.send")}
                </StyledButton>
              </form>
            </>
          )}
        {resetResponse?.success && (
          <Typography color="primary">{T("resetpass.success")}</Typography>
        )}
        {resetResponse && !resetResponse.success && (
          <Typography color="primary">{T("resetpass.success")}</Typography>
        )}
        {newRequestResponse?.email && (
          <Typography color="primary">
            {T("login.forgot.success", { email: newRequestResponse.email })}
          </Typography>
        )}
        {passError && (
          <Typography color="primary">{T("resetpass.diff")}</Typography>
        )}
      </Container>
    </Box>
  );
}
