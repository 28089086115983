import {
  Box,
  Typography as MuiTypography,
  TypographyProps,
  styled,
} from "@mui/material";

export const Typography = styled(({ sx, ...props }: TypographyProps) => (
  <MuiTypography sx={{ mb: 2, ...sx }} {...props} />
))(() => ({}));

const PrivacyPolicy = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: "#fafafa",
        padding: 2,
      }}
    >
      <Typography variant="h3" sx={{ mb: 3 }}>
        Adatkezelési tájékoztató
      </Typography>

      <Typography variant="h4" sx={{ mb: 3 }}>
        1 Az adatkezelési tájékoztató célja
      </Typography>
      <Typography>
        A Mohanet Zrt. (1152 Budapest, Telek u. 7-9), a továbbiakban,
        szolgáltató, adatkezelő) mint adatkezelő, magára nézve kötelezőnek
        ismeri el jelen jogi közlemény tartalmát. Kötelezettséget vállal arra,
        hogy tevékenységével kapcsolatos minden adatkezelés megfelel a jelen
        szabályzatban és a hatályos nemzeti jogszabályokban, valamint az Európai
        Unió jogi aktusaiban meghatározott elvárásoknak.
      </Typography>
      <Typography>
        A Mohanet Zrt. fenntartja magának a jogot jelen tájékoztató bármikori
        megváltoztatására. Természetesen az esetleges változásokról kellő időben
        értesíti közönségét.
      </Typography>
      <Typography>
        Amennyiben kérdése lenne jelen közleményünkhöz kapcsolódóan, kérjük,
        írja meg nekünk, és kollégánk megválaszolja kérdését.
      </Typography>
      <Typography>
        A Mohanet Zrt. elkötelezett ügyfelei és partnerei személyes adatainak
        védelmében, kiemelten fontosnak tartja ügyfelei információs
        önrendelkezési jogának tiszteletben tartását. A Mohanet Zrt. a személyes
        adatokat bizalmasan kezeli, és megtesz minden olyan biztonsági,
        technikai és szervezési intézkedést, mely az adatok biztonságát
        garantálja.
      </Typography>
      <Typography>
        A Mohanet Zrt. az alábbiakban ismerteti adatkezelési gyakorlatát.
      </Typography>

      <Typography variant="h4" sx={{ mb: 3 }}>
        2 Az adatkezelő adatai
      </Typography>
      <Typography>
        Amennyiben megkeresné Társaságunkat, az{" "}
        <a href="mailto:info@mohanet.com">info@mohanet.com</a> elérhetőségen
        léphet kapcsolatba az adatkezelővel.
      </Typography>
      <Typography>
        A Mohanet Zrt. minden hozzá beérkezett e-mailt a személyes adatokkal
        együtt az adatközléstől számított legfeljebb 2 év elteltével töröl.
      </Typography>
      <Typography>
        Név: Mohanet Zrt.
        <br />
        Székhely: 1152 Budapest, Telek u. 7-9
        <br />
        Cégjegyzékszám: 01-10-046541
        <br />
        Adószám: 11689234-2-42
        <br />
        E-mail: <a href="mailto:info@mohanet.com">info@mohanet.com</a>
      </Typography>

      <Typography variant="h4" sx={{ mb: 3 }}>
        3 A kezelt személyes adatok köre
      </Typography>
      <Typography variant="h5">
        3.1 Monitoringbook Home regisztráció során megadandó személyes adatok
      </Typography>
      <Typography>
        Név: Nem kötelező valós nevet megadni.
        <br />
        E-mail cím: Kötelező megadni. Csak az elfelejtett jelszó
        visszaállításához használjuk.
      </Typography>

      <Typography variant="h5">3.2 Technikai adatok</Typography>
      <Typography>
        A Mohanet Zrt. a személyes adatok kezeléséhez a szolgáltatás nyújtása
        során alkalmazott informatikai eszközöket úgy választja meg és
        üzemelteti, hogy a kezelt adat:
      </Typography>
      <ul style={{ marginTop: 0 }}>
        <Typography component="li" sx={{ mb: 0 }}>
          az arra feljogosítottak számára hozzáférhető (rendelkezésre állás);
        </Typography>
        <Typography component="li" sx={{ mb: 0 }}>
          hitelessége és hitelesítése biztosított (adatkezelés hitelessége);
        </Typography>
        <Typography component="li" sx={{ mb: 0 }}>
          változatlansága igazolható (adatintegritás);
        </Typography>
        <Typography component="li" sx={{ mb: 0 }}>
          a jogosulatlan hozzáférés ellen védett (adat bizalmassága) legyen.
        </Typography>
      </ul>
      <Typography>
        A Mohanet Zrt. az adatokat megfelelő intézkedésekkel védi a jogosulatlan
        hozzáférés, megváltoztatás, továbbítás, nyilvánosságra hozatal, törlés
        vagy megsemmisítés, valamint a véletlen megsemmisülés ellen.
      </Typography>
      <Typography>
        A Mohanet Zrt. olyan műszaki, szervezési és szervezeti intézkedésekkel
        gondoskodik az adatkezelés biztonságának védelméről, amely az
        adatkezeléssel kapcsolatban jelentkező kockázatoknak megfelelő védelmi
        szintet nyújt.
      </Typography>
      <Typography>A Mohanet Zrt. az adatkezelés során megőrzi</Typography>
      <ul style={{ marginTop: 0 }}>
        <Typography component="li" sx={{ mb: 0 }}>
          a titkosságot: megvédi az információt, hogy csak az férhessen hozzá,
          aki erre jogosult;
        </Typography>
        <Typography component="li" sx={{ mb: 0 }}>
          a sértetlenséget: megvédi az információnak és a feldolgozás
          módszerének a pontosságát és teljességét;
        </Typography>
        <Typography component="li" sx={{ mb: 0 }}>
          a rendelkezésre állást: gondoskodik arról, hogy amikor a jogosult
          használónak szüksége van rá, valóban hozzá tudjon férni a kívánt
          információhoz, és rendelkezésre álljanak az ezzel kapcsolatos
          eszközök.
        </Typography>
      </ul>

      <Typography variant="h5">3.3 Cookie-k (Sütik)</Typography>
      <Typography variant="h6">3.3.1 A sütik feladata</Typography>
      <ul style={{ marginTop: 0 }}>
        <Typography component="li" sx={{ mb: 0 }}>
          információkat gyűjtenek a látogatókról és eszközeikről;
        </Typography>
        <Typography component="li" sx={{ mb: 0 }}>
          megkönnyítik az alkalmazás használatát;
        </Typography>
        <Typography component="li" sx={{ mb: 0 }}>
          minőségi felhasználói élményt biztosítanak.
        </Typography>
      </ul>
      <Typography>
        A testre szabott kiszolgálás érdekében a felhasználó (telefon vagy
        tablet) készülékén kis adatcsomagot, ún. sütit (cookie) helyez el és a
        későbbi alkalmazáshasználat során olvas vissza.
      </Typography>

      <Typography variant="h6">
        3.3.2 Feltétlenül szükséges, munkamenet (session) cookie-k
      </Typography>
      <Typography>
        Ezen sütik célja, hogy a látogatók maradéktalanul és zökkenőmentesen
        használhassák az MonitoringBook Home alkalmazást, használhassák annak
        funkcióit, és az ott elérhető szolgáltatásokat. Az ilyen típusú sütik
        érvényességi ideje a munkamenet befejezéséig tart, az alkalmazás
        bezárásával a sütik e fajtája automatikusan törlődik a készülékről.
      </Typography>

      <Typography variant="h5">
        3.4 Harmadik félnek továbbított technikai adatok
      </Typography>
      <Typography>
        Az esetleges programhibák felderítésének céljából az alkalmazás
        technikai adatokat (készülék típusa, operációs rendszere,
        internetkapcsolat típusa) személyekhez nem köthető módon továbbít a
        Sentry (
        <a href="https://sentry.io/privacy/">https://sentry.io/privacy/</a>)
        rendszerbe.
      </Typography>
      <Typography>
        A push üzenetek küldéséhez a Firebase Cloud Messaging (
        <a href="https://firebase.google.com/support/privacy">
          https://firebase.google.com/support/privacy
        </a>
        ) szolgáltatást használjuk.
      </Typography>

      <Typography variant="h4" sx={{ mb: 3 }}>
        4 A kezelt adatok tervezett felhasználása és megőrzési ideje
      </Typography>
      <table>
        <tbody>
          <tr style={{ textAlign: "left" }}>
            <th style={{ paddingRight: 20 }}>Adatkezelés neve</th>
            <td style={{ paddingRight: 20 }}>Név</td>
          </tr>
          <tr style={{ textAlign: "left" }}>
            <th style={{ paddingRight: 20 }}>Felhasználása</th>
            <td style={{ paddingRight: 20 }}>Felhasználó azonosítása</td>
          </tr>
          <tr style={{ textAlign: "left" }}>
            <th style={{ paddingRight: 20 }}>Jogalap</th>
            <td style={{ paddingRight: 20 }}>Önkéntes adatszolgáltatás</td>
          </tr>
          <tr style={{ textAlign: "left" }}>
            <th style={{ paddingRight: 20 }}>Megőrzési idő</th>
            <td style={{ paddingRight: 20 }}>Alkalmazás használatának ideje</td>
          </tr>
        </tbody>
      </table>

      <Typography variant="h4" sx={{ mb: 3 }}>
        5 Az adatkezelés célja, módja és jogalapja
      </Typography>
      <Typography variant="h5">
        5.1 Általános adatkezelési irányelvek
      </Typography>
      <Typography>
        A Mohanet Zrt. tevékenységének adatkezelései önkéntes hozzájáruláson,
        illetve törvényi felhatalmazáson alapulnak. Az önkéntes hozzájáruláson
        alapuló adatkezelések esetében az érintettek e hozzájárulásukat az
        adatkezelés bármely szakában visszavonhatják.
      </Typography>
      <Typography>
        Bizonyos esetekben a megadott adatok egy körének kezelését, tárolását,
        továbbítását jogszabályok teszik kötelezővé, melyről külön értesítjük
        ügyfeleinket.
      </Typography>
      <Typography>
        Felhívjuk a Mohanet Zrt. részére adatközlők figyelmét, hogy amennyiben
        nem saját személyes adataikat adják meg, az adatközlő kötelessége az
        érintett hozzájárulásának beszerzése.
      </Typography>
      <Typography>
        Adatkezelési alapelvei összhangban vannak az adatvédelemmel kapcsolatos
        hatályos jogszabályokkal, így különösen az alábbiakkal:
      </Typography>
      <ul style={{ marginTop: 0 }}>
        <Typography component="li" sx={{ mb: 0 }}>
          2011. évi CXII. törvény - az információs önrendelkezési jogról és az
          információ-szabadságról (Infotv.);
        </Typography>
        <Typography component="li" sx={{ mb: 0 }}>
          Az Európai Parlament és a Tanács (EU) 2016/679 rendelete (2016.
          április 27.) – a természetes személyeknek a személyes adatok kezelése
          tekintetében tör-ténő védelméről és az ilyen adatok szabad
          áramlásáról, valamint a 95/46/EK rendelet hatályon kívül helyezéséről
          (általános adatvédelmi rendelet, GDPR);
        </Typography>
        <Typography component="li" sx={{ mb: 0 }}>
          2013. évi V. törvény – a Polgári Törvénykönyvről (Ptk.);
        </Typography>
        <Typography component="li" sx={{ mb: 0 }}>
          2000. évi C. törvény – a számvitelről (Számv. tv.);
        </Typography>
        <Typography component="li" sx={{ mb: 0 }}>
          2017. évi LIII. törvény – a pénzmosás és terrorizmus finanszírozása
          megelőzéséről és megakadályozásáról (Pmt.);
        </Typography>
        <Typography component="li" sx={{ mb: 0 }}>
          2013. évi CCXXXVII. törvény – a hitelintézetekről és a pénzügyi
          vállalkozások-ról (Hpt.).
        </Typography>
      </ul>

      <Typography variant="h4" sx={{ mb: 3 }}>
        6 Adattovábbítás, adatfeldogozás, az adatokat megismerők köre
      </Typography>
      <Typography>
        Az adatokat elsődlegesen Adatkezelő, illetve Adatkezelő azon belső
        munkatársai jogosultak megismerni, akiknek az adat megismerése és
        kezelése a munkaköri feladataihoz kapcsolódik.
      </Typography>

      <Typography variant="h4" sx={{ mb: 3 }}>
        7 Érintett jogai és jogérvényesítési lehetőségei
      </Typography>
      <Typography>
        Az érintett tájékoztatást kérhet személyes adatai kezeléséről, valamint
        kérheti személyes adatainak helyesbítését, illetve – a kötelező
        adatkezelések kivételével – törlését, visszavonását, élhet
        adathordozási-, és tiltakozási jogával az adat felvételénél jelzett
        módon, illetve az adatkezelő fenti elérhetőségein.
      </Typography>
      <Typography variant="h5">7.1 Tájékoztatáshoz való jog</Typography>
      <Typography>
        A Mohanet Zrt. megfelelő intézkedéseket hoz annak érdekében, hogy az
        érintettek részére a személyes adatok kezelésére vonatkozó, a GDPR 13.
        és a 14. cikkben említett valamennyi információt és a 15-22. és 34. cikk
        szerinti minden egyes tájékoztatást tömör, átlátható, érthető és könnyen
        hozzáférhető formában, világosan és közérthetően megfogalmazva nyújtsa.
      </Typography>

      <Typography variant="h5">
        7.2 Az érintett hozzáféréshez való joga
      </Typography>
      <Typography>
        Az érintett jogosult arra, hogy az adatkezelőtől visszajelzést kapjon
        arra vonatkozóan, hogy személyes adatainak kezelése folyamatban van-e,
        és ha ilyen adatkezelés folyamatban van, jogosult arra, hogy a személyes
        adatokhoz és a következő információkhoz hozzáférést kapjon: az
        adatkezelés céljai; az érintett személyes adatok kategóriái; azon
        címzettek vagy címzettek kategóriái, akikkel, illetve amelyekkel a
        személyes adatokat közölték vagy közölni fogják, ideértve különösen a
        harmadik országbeli címzetteket, illetve a nemzetközi szervezeteket; a
        személyes adatok tárolásának tervezett időtartama; a helyesbítés, törlés
        vagy adatkezelés korlátozásának és a tiltakozás joga; a felügyeleti
        hatósághoz címzett panasz benyújtásának joga; az adatforrásokra
        vonatkozó információ; az automatizált döntéshozatal ténye, ideértve a
        profilalkotást is, valamint az alkalmazott logikára és arra vonatkozó
        érthető információk, hogy az ilyen adatkezelés milyen jelentőséggel bír,
        és az érintettre nézve milyen várható következményekkel jár. Az
        adatkezelő a kérelem benyújtásától számított legfeljebb egy hónapon
        belül adja meg a tájékoztatást.
      </Typography>

      <Typography variant="h5">7.3 Helyesbítés joga</Typography>
      <Typography>
        Az érintett kérheti a Mohanet Zrt. által kezelt, rá vonatkozó pontatlan
        személyes adatok helyesbítését és a hiányos adatok kiegészítését.
      </Typography>

      <Typography variant="h5">7.4 Törléshez való jog</Typography>
      <Typography>
        Az érintett az alábbi indokok valamelyikének fennállása esetén jogosult
        arra, hogy kérésére a Mohanet Zrt. indokolatlan késedelem nélkül törölje
        a rá vonatkozó személyes adatokat:
      </Typography>
      <ul style={{ marginTop: 0 }}>
        <Typography component="li" sx={{ mb: 0 }}>
          személyes adatokra már nincs szükség abból a célból, amelyből azokat
          gyűjtötték vagy más módon kezelték;
        </Typography>
        <Typography component="li" sx={{ mb: 0 }}>
          az érintett visszavonja az adatkezelés alapját képező hozzájárulását,
          és az adatkezelésnek nincs más jogalapja;
        </Typography>
        <Typography component="li" sx={{ mb: 0 }}>
          az érintett tiltakozik az adatkezelés ellen, és nincs elsőbbséget
          élvező jogszerű ok az adatkezelésre;
        </Typography>
        <Typography component="li" sx={{ mb: 0 }}>
          a személyes adatokat jogellenesen kezelték;
        </Typography>
        <Typography component="li" sx={{ mb: 0 }}>
          a személyes adatokat az adatkezelőre alkalmazandó uniós vagy tagállami
          jogban előírt jogi kötelezettség teljesítéséhez törölni kell;
        </Typography>
        <Typography component="li" sx={{ mb: 0 }}>
          a személyes adatok gyűjtésére információs társadalommal összefüggő
          szolgáltatások kínálásával kapcsolatosan került sor.
        </Typography>
      </ul>
      <Typography>
        Az adatok törlése nem kezdeményezhető, ha az adatkezelés szükséges: a
        véleménynyil-vánítás szabadságához és a tájékozódáshoz való jog
        gyakorlása céljából; a személyes adatok kezelését előíró, az
        adatkezelőre alkalmazandó uniós vagy tagállami jog szerinti
        kötelezettség teljesítése, illetve közérdekből vagy az adatkezelőre
        ruházott közhatalmi jogosítvány gyakorlása keretében végzett feladat
        végrehajtása céljából; a népegészség-ügy területét érintő, vagy
        archiválási, tudományos és történelmi kutatási célból vagy statisztikai
        célból, közérdek alapján; vagy jogi igények előterjesztéséhez,
        érvényesítéséhez, illetve védelméhez.
      </Typography>

      <Typography variant="h5">
        7.5 Az adatkezelés korlátozásához való jog
      </Typography>
      <Typography>
        Az érintett kérésére a Mohanet Zrt. korlátozza az adatkezelést, ha az
        alábbi feltételek valamelyike teljesül:
      </Typography>
      <ul style={{ marginTop: 0 }}>
        <Typography component="li" sx={{ mb: 0 }}>
          az érintett vitatja a személyes adatok pontosságát, ez esetben a
          korlátozás arra az időtartamra vonatkozik, amely lehetővé teszi, a
          személyes adatok pontosságának ellenőrzését;
        </Typography>
        <Typography component="li" sx={{ mb: 0 }}>
          az adatkezelés jogellenes, és az érintett ellenzi az adatok törlését,
          és ehelyett kéri azok felhasználásának korlátozását;
        </Typography>
        <Typography component="li" sx={{ mb: 0 }}>
          az adatkezelőnek már nincs szüksége a személyes adatokra adatkezelés
          céljából, de az érintett igényli azokat jogi igények
          előterjesztéséhez, érvényesítéséhez vagy védelméhez; vagy
        </Typography>
        <Typography component="li" sx={{ mb: 0 }}>
          az érintett tiltakozott az adatkezelés ellen; ez esetben a korlátozás
          arra az időtartamra vonatkozik, amíg megállapításra nem kerül, hogy az
          adatkezelő jogos indokai elsőbbséget élveznek-e az érintett jogos
          indokaival szemben.
        </Typography>
      </ul>
      <Typography>
        Ha az adatkezelés korlátozás alá esik, a személyes adatokat a tárolás
        kivételével csak az érintett hozzájárulásával, vagy jogi igények
        előterjesztéséhez, érvényesítéséhez vagy vé-delméhez, vagy más
        természetes vagy jogi személy jogainak védelme érdekében, vagy az Unió,
        illetve valamely tagállam fontos közérdekéből lehet kezelni.
      </Typography>
      <Typography variant="h5">7.6 Adathordozáshoz való jog</Typography>
      <Typography>
        Az érintett jogosult arra, hogy a rá vonatkozó, általa az adatkezelő
        rendelkezésére bocsátott személyes adatokat tagolt, széles körben
        használt, géppel olvasható formátumban megkapja, és ezeket az adatokat
        egy másik adatkezelőnek továbbítsa.
      </Typography>
      <Typography variant="h5">7.7 Tiltakozás joga</Typography>
      <Typography>
        Az érintett jogosult arra, hogy a saját helyzetével kapcsolatos okokból
        bármikor tiltakozzon személyes adatainak közérdekű vagy az adatkezelőre
        ruházott közhatalmi jogosítvány gyakorlásának keretében végzett feladat
        végrehajtásához szükséges adatkezelés, vagy az adatkezelő vagy egy
        harmadik fél jogos érdekeinek érvényesítéséhez szükséges kezelése ellen,
        ideértve az említett rendelkezéseken alapuló profilalkotást is.
        Tiltakozás esetén az adatkezelő a személyes adatokat nem kezelheti
        tovább, kivéve, ha azt olyan kényszerítő erejű jogos okok indokolják,
        amelyek elsőbbséget élveznek az érintett érdekeivel, jogaival és
        szabadságaival szemben, vagy amelyek jogi igények előterjesztéséhez,
        érvényesítéséhez vagy védelméhez kapcsolódnak.
      </Typography>
      <Typography variant="h5">
        7.8 Automatizált döntéshozatal egyedi ügyekben, beleértve a
        profilalkotást
      </Typography>
      <Typography>
        Az érintett jogosult arra, hogy ne terjedjen ki rá az olyan, kizárólag
        automatizált adatkezelésen – ideértve a profilalkotást is – alapuló
        döntés hatálya, amely rá nézve joghatással járna vagy őt hasonlóképpen
        jelentős mértékben érintené.
      </Typography>
      <Typography variant="h5">7.9 Visszavonás joga</Typography>
      <Typography>
        Az érintett jogosult arra, hogy hozzájárulását bármikor visszavonja.
      </Typography>
      <Typography variant="h5">7.10 Bírósághoz fordulás joga</Typography>
      <Typography>
        Az érintett a jogainak megsértése esetén az adatkezelő ellen bírósághoz
        fordulhat. A bíróság az ügyben soron kívül jár el.
      </Typography>
      <Typography variant="h5">7.11 Adatvédelmi hatósági eljárás</Typography>
      <Typography>
        Panasszal a Nemzeti Adatvédelmi és Információszabadság Hatóságnál lehet
        élni: Név: Nemzeti Adatvédelmi és Információszabadság Hatóság
        <br />
        Székhely: 1125 Budapest, Szilágyi Erzsébet fasor 22/C. Levelezési cím:
        1530 Budapest, Pf.: 5.
        <br />
        Telefon: 0613911400
        <br />
        Fax: 0613911410
        <br />
        E-mail:{" "}
        <a href="mailto:ugyfelszolgalat@naih.hu">ugyfelszolgalat@naih.hu</a>
        <br />
        Honlap: <a href="http://www.naih.hu">http://www.naih.hu</a>
      </Typography>

      <Typography variant="h4" sx={{ mb: 3 }}>
        8 Egyéb rendelkezések
      </Typography>
      <Typography>
        E tájékoztatóban fel nem sorolt adatkezelésekről az adat felvételekor
        adunk tájékoztatást.
      </Typography>
      <Typography>
        Tájékoztatjuk ügyfeleinket, hogy a bíróság, az ügyész, a nyomozó
        hatóság, a szabálysértési hatóság, a közigazgatási hatóság, a Nemzeti
        Adatvédelmi és Információszabadság Hatóság, a Magyar Nemzeti Bank,
        illetőleg jogszabály felhatalmazása alapján más szervek tájékoztatás
        adása, adatok közlése, átadása, illetőleg iratok rendelkezésre bocsátása
        végett megkereshetik az adatkezelőt.
      </Typography>
      <Typography>
        A Mohanet Zrt. a hatóságok részére – amennyiben a hatóság a pontos célt
        és az adatok körét megjelölte – személyes adatot csak annyit és olyan
        mértékben ad ki, amely a megkeresés céljának megvalósításához
        elengedhetetlenül szükséges.
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;
