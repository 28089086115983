import googleplay from "../img/googleplay.svg";
import appstore from "../img/appstore.svg";
import { Typography, useTheme } from "@mui/material";
import { T } from "../services/TranslateService";
// import { Button } from "@mui/material";
// import { T } from "../services/TranslateService";

const AppStoreLinks = () => {
  const theme = useTheme();
  return (
    <>
      <Typography sx={{ color: (theme) => theme.palette.text.primary }}>
        {T("web.app-downloads")}
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <a
          href="https://play.google.com/store/apps/details?id=hu.mohanet.monitoringbook"
          style={{ margin: 10 }}
        >
          <img src={googleplay} width="200" alt="Google Play" />
        </a>
        <a
          href="https://apps.apple.com/us/app/monitoringbook/id933965125"
          style={{ margin: 10 }}
        >
          <img src={appstore} width="200" alt="App Store" />
        </a>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <a
          href="https://home.monitoringbook.com"
          style={{ margin: 10, textTransform: "none", color: theme.palette.text.primary }}
        >
          {T("web.oldversion")}
        </a>
      </div>
    </>
  );
};

export default AppStoreLinks;
