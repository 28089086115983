import { useMemo } from "react";
import { useLocation } from "react-router-dom";


import { setLang } from "../services/TranslateService";

import logo from "../img/logo.png";
import { backgroundImage } from "@mb-home/lib";
import AppStoreLinks from "../components/AppStoreLinks";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function Home() {
  const query = useQuery();
  setLang(query.get("lang") || localStorage.lng);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        height: "100vh",
        minHeight: 460,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <img src={logo} width="220" alt="logo" />
      <AppStoreLinks />
    </div>
  );
}
