import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./pages/Home";
import ResetPassword from "./pages/ResetPassword";
import { ThemeProvider, createTheme } from "@mui/material";
import AccountRemove from "./pages/AccountRemove";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#4e99af",
    },
  },
  shape: {
    borderRadius: 25,
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/reset-password">
            <ResetPassword />
          </Route>
          <Route path="/account-remove">
            <AccountRemove />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Redirect to="/" />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
